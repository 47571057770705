import styled, {css} from "styled-components";

const BoldFont = 'Rubik-Medium';
// const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const MainContainer = styled.div`
  width: 100%;
`;
const ExperienceMainContainer = styled.div`
  width: 200px;
  float: left;
  @media (max-width: 506px){
    width: 100%;
  }
`;
const GraphsContainer = styled.div`
  width: 100%;
  
`;
const TitleHeader = styled.div`
  background-color: #1E76AB;
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  width: 100%;
  padding: 10px 15px 10px 42px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  >div{
    h4{
      font-family: ${BoldFont};
      font-size: 16px;
    }
    p{
      font-family: ${MediumFont};
      font-size: 16px;
      line-height: 10px;
      color: #fff;
    }
  }
  @media (max-width: 750px){
    padding: 10px;
    flex-direction: column;
    > div{
      text-align: center;
    }
  }
`;
const SplitFitnessSection = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '33%'};
  padding: ${({gridWidth}) => gridWidth ? '0 5px 0 5px' : '0 10px 0 0'}
  float: left;
  display: ${({displayNone}) => displayNone && 'none'};
  height: 300px;
  margin-bottom: 10px;
  @media (max-width: 1260px) {
    padding-right: 15px;
  }
  @media (max-width: 1200px) {
      width: ${({ShowActiveUserSection}) => ShowActiveUserSection && '100%'};
      float: left;
  }
  @media (max-width: 1150px) {
    width: calc(100% / 2);
  } 
  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 15px;
    padding:${({ smallPadding }) => smallPadding && smallPadding };
  }
  .boxShadow {
    box-shadow: 0 0 21px rgba(0,0,0,0.08);
    float: left;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    .card-top {
      float: left;
      width: 100%;
      background-color: #1E76AB;
      border-radius: 6px 6px 0px 0px;
    }
    .groupActivity {
      height: 52px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: ${BoldFont};
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    
    .statistic-bar {
      background-color: #1E76AB;
      min-height: 30px;
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      > p {
      font-family: ${MediumFont};
      font-size: 10px;
      line-height: 22px;
      color: #FFFFFF;
      margin: 0;
    }
    .radio-button-container {
      display: flex;
      background: #fff;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      border-radius: 4px;
      padding: 0 5px;
      width: 60%
      display: none
    }
  } 
`;
const FitnessCommonContainer = styled.div`
  width: 100%;
  float: left;
  height: ${({wellnessGoals}) => wellnessGoals ? 'calc(100% - 60px)': 'calc(100% - 106px)'};
  //background-color:  ${({bgColor}) => bgColor ? bgColor : 'white'}; 
  padding: ${({padding}) => padding ? padding : '10px'};
  //border-top: ${({ borderTop }) => borderTop ? '1px solid #e9e8e8' : ''};
  border-bottom: ${({ borderBottom }) => borderBottom ? '2px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };
  }
  
  @media (min-width: 1101px) and (max-width: 1250px) {
    height: calc(100% - 106px);
  }
  
  & > div:last-child {
  border-radius:  0 0 6px 6px;
  height: 100%;
  }
  
  .fitnessDeviceHeight {
    padding-top: 10px;
  }
  .highcharts-credits {
    display: none; 
  }

  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    padding: 2px;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }

  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  
  ${({ flex }) => flex && css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    overflow: auto;
  `}
`;
const Flexbox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > div {
    .highcharts-container {
      width: 100% !important;
      > svg {
        width: 100%;
        .xAxis {
          > text {
            font-size: 11px !important;
            @media (max-width: 1000px) {
              font-size: 12px !important;
            }
          }
        }
      }  
    } 
  }
`;
const CurrentYear = styled.div`
   float: right;
   padding-right: 5px;
   & > p {
    font-size: 14px;
    margin: 0;
    color: #fff;
    margin-right: 7px;
   } 
`;
const TitleContainer = styled.div`
    float: left;
    width: 100%;
    padding: ${({padding}) => padding ? padding : '5px 0 5px 5px'};
    background-color: ${({bgColor}) => bgColor ? bgColor : 'none'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : 'none'};
    border-bottom: 2px solid rgb(237, 237, 237);
    height: 90px;
    & > img {
      float: left;
    }
    & > div {
      float: right;
      display: flex;
      align-items: center; 
      > a {
        display: block;
        font-size: 14px;
        color: rgb(51,51,51); 
        font-family: ${BoldFont};
       
      } 
      > img {
          width: 8px;
          margin-left: 10px;
      } 
      > i {
          font-size: 25px;
          color: #fff;
          line-height: 0;
      }  
    }
   @media (max-width: 1237px) and (min-width: 1100px) {
    height: 98px;
   }
   @media (max-width: 975px) and (min-width: 900px) {
    height: 98px;
   }
   @media (max-width: 780px) and (min-width: 760px) {
    height: 98px;
   } 
`;
const Title = styled.p`
   font-size: ${({fontSize}) => fontSize ? fontSize : '14px'};
   letter-spacing: 0.8px;
   color: rgb(51,51,51);
   font-family: ${MediumFont};
   font-weight: ${({fontWeight}) => fontWeight ? fontWeight : '700'};
   text-transform: ${({textTransform}) => textTransform ? 'uppercase' : 'none'};
   line-height: 18px;
   text-align: left;
   float: left;
   margin: 0;
   color: ${({textColor}) => textColor ? textColor : 'none'};
   max-width: ${({maxWidth}) => maxWidth ? maxWidth : 'none'};
   padding: ${({padding}) => padding ? padding : '0'};
   
   @media (max-width: 1200px) and (min-width: 1024px) {
    max-width: none;
   }
   
   @media (max-width: 1023px) and (min-width: 700px) {
    max-width: 600px;
   } 
`;
const ExperienceMain = styled.div`
  width: calc(100% - 200px);
  float: left;
  padding-left: 15px;
  display: block;
  >div{
    width: 100%;
  }
  @media (max-width: 506px){
    width: 100%;
    padding-left: 0px;
  }
`;
const LaunchSurvey = styled.button`
  width: 178px;
  height: 40px;
  background-color: #4798D9;
  color: #FFFFFF;
  border-radius: 6px;
  border: none;
  outline: none;
`;
export {MainContainer, ExperienceMainContainer, ExperienceMain, TitleHeader, SplitFitnessSection, FitnessCommonContainer,Flexbox, CurrentYear, TitleContainer, Title, GraphsContainer, LaunchSurvey};
