import React, {Component} from 'react';
import "./styles.css";
import PropTypes from 'prop-types';
import CSVList from '../../../AttendeesModal/CSVList';
import { connect } from 'react-redux';

class RecognitionCustomDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeTab: 0,
      label: props.recognitionDropDown ? "Recognition Activity":"ENGAGEMENT",
      listOpen: false,
      printGraphCSV: false,
      updatedGraphCSV: []
    };
    this.myref = React.createRef();
  }

  handleActiveTab = (active, tab) => this.setState({activeTab: active}, ()=>{
    this.setState({label: tab.label});
    this.setState((prev) => ({listOpen: !prev.listOpen}));
    this.props.getActiveTab(active)
  });
  toggleList = () => {
    this.setState((prev) => ({listOpen: !prev.listOpen}));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    // const {getRecognitionDetails,companyId} = this.props
    // let currentYear = new Date().getFullYear();
    // getRecognitionDetails(companyId, currentYear)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  // handleClick = (e) => {
  //   if (!(_.isNull(this.myref)) && !(_.isUndefined(this.myref)) && this.myref && this.myref.current.contains && !this.myref.current.contains(e.target) && this.state.listOpen === true ) {
  //     this.setState({listOpen: false});
  //   }
  // };
  handleClick = (e) => {
    if (this.myref && this.myref.current && this.myref.current.contains && !this.myref.current.contains(e.target) && this.state.listOpen === true) {
      this.setState({ listOpen: false });
    }
  };

  // printAllData = (bool) => {
  //   this.printAction(bool);
  // }

  // printAction = (bool) => {
  //   const { recognitionDetails } = this.props;
  //   let updatedGraphCSV = recognitionDetails && recognitionDetails.length > 0 &&
  //   recognitionDetails.map((data) => {
  //     let obj = {};
  //     obj['NAME'] = data['name'];
  //     obj['PERCENTAGE'] = data['percentage'];
  //     obj['COUNT'] = data['count'];
  //     return obj;
  //   })
  //   this.setState({ printGraphCSV: bool, updatedGraphCSV: updatedGraphCSV })
  // };

  render() {
    const { updatedGraphCSV,printGraphCSV} = this.state;
    const {tabs, activeTab} = this.props;

    const fileTitle = "BarGraphData"
    const csvHeaders = 
      [
        {label: "NAME", key: "NAME"},
        {label: "Percentage", key: "PERCENTAGE"},
        {label: "Count", key: "COUNT"}
      ] 
    return (
      <div className="tab-component-containerV2">
        <div className="tab-wrapperV2">
          {/* <div className="tabV2">
            <div className="tab-dropdownV2" ref={this.myref}>
              <button className="dropbtnV2" onClick={()=>this.toggleList()}>{label}
                {listOpen ? <i className="fa fa-caret-up" />

                  : <i className="fa fa-caret-down" /> }
              </button>
              {
                listOpen && tabs.map((tab, index) => (
                  <button
                    key = {index}
                    onClick={() => this.handleActiveTab(index, tab)}
                    className={`dropbtnV2 ${activeTab === index && 'activeLabelV2'}`}>{tab.label}
                  </button>
                ))
              }
            </div>
            {
              tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tabContainerV2 ${activeTab === index && 'activeLabelV2'}`}>
                  <button
                    onClick={() => this.handleActiveTab(index, tab)}
                    // className={`tabLabel ${activeTab === index && 'activeTab'}`}
                    className={`tabLabelNewV2 ${activeTab === index && 'activeTabNewV2'}`}
                  >{tab.label}
                  </button>
                </div>
              ))
            }
          </div> */}
          {/* <button className='download-btnV2' onClick={() => this.printAllData(true)}>
            <img className='download-imgV2' src='/images/AdminEngagement/NewDownload.png'></img>
            <span className='download-txtV2'>Download</span>
          </button> */}
        </div>
        <div className="tab-component-parentV2">
          {
            tabs[activeTab].component
          }
        </div>
        {printGraphCSV && <CSVList Title={fileTitle} removeCSVData={this.printAction} headers={csvHeaders} data={updatedGraphCSV} />}
      </div>

    )
  }
}

RecognitionCustomDropDown.propTypes = {
  tabs: PropTypes.array,
  history: PropTypes.object,
  recognitionDropDown: PropTypes.number,
  // getRecognitionDetails: PropTypes.func,
  // recognitionDetails: PropTypes.array,
  companyId: PropTypes.number,
  getActiveTab: PropTypes.func,
  activeTab : PropTypes.any
};

const mapStateToProps = () => ({
  // recognitionDetails: state.companyDashboard.recognitionDetails
});

const mapDispatchToProps = () => ({
  // getRecognitionDetails: (id, year) => dispatch(getRecognitionDetails(id, year))
});
export default connect(mapStateToProps, mapDispatchToProps)(RecognitionCustomDropDown);
